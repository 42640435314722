import firebase from "gatsby-plugin-firebase"
import React, { useState, useEffect } from "react"
import Layout from "../../../components/layout"
import { DownOutlined } from "@ant-design/icons"
import {
  Table,
  Button,
  Menu,
  Dropdown,
  Popconfirm,
  Card,
  Divider,
  Breadcrumb,
} from "antd"
import { Link, navigate } from "gatsby"
import { authService } from "../../../services/auth.service"
import { CpiService } from "../../../services/cpi.service"
import PageHeading from "../../../components/pageHeading"
import ProfileImgUploader from "../../../components/profileImgUploader"
import DialogModal from "../../../components/dialogModal"
import Header from "../../../components/header"
const { Column } = Table

export default function Instrument({ location }) {
  const [cpiData, setCpiData] = useState([])
  const [modalShow, setModalShow] = useState(false)
  // const [guideModalShow, setGuideModalShow] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRow, setSelectedRow] = useState({})
  const placeholderImg =
    "https://blog.greendot.org/wp-content/uploads/sites/13/2021/09/placeholder-image-610x458.png"
  // https://dummyimage.com/600x400/ffffff/000000.png&text=Upload+Profile+Image
  const [introModalShow, setIntroModalShow] = useState(false)
  // convert the timestamp to date

  let create_timestamp = 0
  let createdTime = ""
  if (selectedRow.createTime !== undefined) {
    create_timestamp = selectedRow.createTime.toJSON().seconds
    let date = new Date(create_timestamp * 1000)
    createdTime = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
  }

  let modify_timestamp = 0
  let modifiedTime = ""
  if (selectedRow.lastModifyTime !== undefined) {
    modify_timestamp = selectedRow.lastModifyTime.toJSON().seconds
    let date = new Date(modify_timestamp * 1000)
    modifiedTime = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
  }

  const showModal = () => {
    //console.log("onClick", introModalShow)
    setIntroModalShow(true)
  }

  useEffect(() => {
    if (introModalShow) {
      setIntroModalShow(false)
    }
  }, [introModalShow])

  const rowSelection = {
    onChange: selectedRowKeys => {
      setSelectedRowKeys(selectedRowKeys)
    },
  }

  useEffect(() => {
    async function init() {
      let uid = await authService.getUser().uid
      let cpi = await CpiService.getAllIns(uid)
      if (cpi !== undefined) displayCpi(cpi)
      {
        console.log(cpi)
      }
    }

    init()
  }, [])

  function displayCpi(cpi) {
    setCpiData(cpi)
    // once get the cpi data then check the cpi data length
    cpi.length !== 0 ? setIntroModalShow(false) : setIntroModalShow(true)
  }

  const handleDelete = key => {
    CpiService.deleteIns(key)
    const dataUpdate = cpiData.filter(item => item.key !== key)
    setCpiData(dataUpdate)
    setSelectedRow({})
  }

  const menu = insId => (
    <Menu>
      {/* mode="horizontal" */}

      <Menu.Item key="1">
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDelete(insId)}
        >
          <a>Delete</a>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  )

  const content = (
    <>
      <p className="pb-1">Dear Content Providers of Record,</p>
      <p>
        Thank you for agreeing to share your fine instrument's information with
        our registry. You have been accepted as a CPR and now you will be adding
        your instrument's information to the registry. There are multiple fields
        you need to complete. Some are private and some will be public. It is up
        to you to decide how much information to share and what of this
        information to make public. Formatting for some fields is self
        explanatory. Frequently these take the form of a checkbox. Others need
        to be presented in a certain way and that is why you are reading these
        notes. Below are the instructions and conventions of how data should be
        entered into the registry. It is a list of each data field that you can
        enter to upload your instrument to the registry. If you encounter any
        questions when completing your information, please refer to these
        protocols. It is our goal to standardize formats across all entries so
        they are consistent for those using the registry.
      </p>
    </>
  )

  // console.log("selectedrow", selectedRow)
  // console.log("selectedrowkeys", selectedRowKeys)

  return (
    <Layout>
      <PageHeading title="Instruments" />

      <DialogModal visible={introModalShow} content={content} />

      <div className="flex flex-row justify-start items-center p-3 pt-3">
        {/* <div className="text-lg">Instrument Table</div> */}
        <Link to="/app/instrument/new">
          <button className="mr-2 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow transition transform  backface-visibility-hidden active:bg-opacity-40 hover:scale-105 hover:bg-opacity-30 focus:outline-none bg-opacity-20 ">
            Add Instrument
          </button>
        </Link>

        <button
          onClick={showModal}
          className="transition transform  backface-visibility-hidden active:bg-opacity-40 hover:scale-105 hover:bg-opacity-30 focus:outline-none bg-opacity-20"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="flex flex-row ">
        <Table
          dataSource={cpiData}
          className=""
          style={{ width: 540 }}
          rowSelection={{
            type: "radio",
            selectedRowKeys,
            ...rowSelection,
          }}
          onRow={r => ({
            onClick: () => {
              setSelectedRow(r)
              setSelectedRowKeys([r.key]) //update selected key according to row select, be mindful of data format
            },
          })}
        >
          <Column
            title="Name"
            dataIndex="name"
            key="name"
            className="break-all"
          />
        </Table>
        {/* Card display image */}
        {Object.entries(selectedRow).length === 0 ? null : (
          <Card
            style={{ width: 540 }}
            className=""
            extra={
              // pr-3 to put all the button in the middle
              <div className="flex pr-3 -mb-1.5 ">
                <Button>
                  <Link to={`/app/instrument/edit/${selectedRow.key}/`}>
                    Edit
                  </Link>
                </Button>
                <Button>
                  <Link to={`/app/instrument/media/${selectedRow.key}/`}>
                    Media Folder
                  </Link>
                </Button>
                <Button>
                  <Link to={`/app/instrument/provenance/${selectedRow.key}/`}>
                    Provenance
                  </Link>
                </Button>
                <Button>
                  <Link
                    to={`/app/instrument/conditionReport/${selectedRow.key}/`}
                  >
                    Condition Report
                  </Link>
                </Button>
                <Dropdown
                  overlay={menu(selectedRow.key)}
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <Button onClick={e => e.preventDefault()}>
                    More{` `}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            }
          >
            {/* ********************************************* */}
            <div className="main flex  ">
              <div className="left w-2/5 ">
                <div className="left-top ">
                  <img
                    src={selectedRow.profileImg || placeholderImg}
                    alt=""
                    className=" py-3 "
                  />
                </div>
                <div className="left-bottom pl-2 ">
                  <div className="underline  ">
                    <Button
                      onClick={() => {
                        setModalShow(true)
                      }}
                    >
                      {selectedRow.profileImg === undefined ? (
                        <div>Upload Profile Image</div>
                      ) : (
                        <div>Update Profile Image</div>
                      )}
                    </Button>
                    <ProfileImgUploader
                      visible={modalShow}
                      onCancel={() => {
                        setModalShow(false)
                      }}
                      path={`/cpi/${selectedRow.key}/`}
                      multiple={false}
                    />
                  </div>
                </div>
              </div>
              <div className="right pl-4 w-3/5 ">
                {/* Type */}

                <div className="font-medium">{selectedRow.type}</div>

                <div className="text-base font-medium">{selectedRow.name} </div>
                <p>{`${selectedRow.city || ""}${
                  selectedRow.city === undefined ? "" : ","
                } ${selectedRow.country || ""}`}</p>
                <p>
                  {selectedRow.makerFirst || ""} {selectedRow.makerLast || ""}
                  {selectedRow.makerFirst === undefined ? "" : "("}
                  {selectedRow.makerBirth || ""}
                  {selectedRow.makerFirst === undefined ? "" : "-"}
                  {selectedRow.makerDeath || ""}
                  {selectedRow.makerFirst === undefined ? "" : ")"}
                </p>
              </div>
            </div>
            <Divider />
            <div className="">
              {/* {console.log(selectedRow)} */}
              <div>
                Created:&nbsp;
                {createdTime}
              </div>
              <div>
                {modifiedTime ? `Last Modified: ` : ""}

                {modifiedTime}
              </div>
            </div>
            {/* ******************Old Card**************************** */}
            {/* <div className="w-full ">
              <div className="flex flex-row justify-between">
                <div className="">{selectedRow.name}</div>
                <div className=" ">
                  <Tag color="blue" key={selectedRow.key} className="mr-0">
                    {selectedRow.cpiStatus}
                  </Tag>
                </div>
              </div>
              <div className="pb-2"></div>

              <div className="flex justify-center">
                <img
                  className=" "
                  src={selectedRow.profileImg || placeholderImg}
                  alt="upload img"
                  width="360"
                />
              </div>

              <div className="underline text-blue-700">
                <Button
                  onClick={() => {
                    setModalShow(true)
                  }}
                >
                  {selectedRow.profileImg === undefined ? (
                    <div>Upload Profile Image</div>
                  ) : (
                    <div>Update Profile Image</div>
                  )}
                </Button>
                <ProfileImgUploader
                  visible={modalShow}
                  onCancel={() => {
                    setModalShow(false)
                  }}
                  path={`/cpi/${selectedRow.key}/`}
                  multiple={false}
                />
              </div>
            </div> */}
            {/* ******************************************* */}
          </Card>
        )}
      </div>
    </Layout>
  )
}
