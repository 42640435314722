import { navigate } from "gatsby-link"
import React from "react"
import { Breadcrumb } from "antd"
import { Link } from "gatsby"

const PageHeading = ({ title }) => (
  <div className="flex w-screen">
    <div className=" pt-10" style={{ width: 1080 }}>
      <div className="py-5 bg-black rounded-xl  flex flex-col   ">
        <Breadcrumb>
          {/* <Breadcrumb.Item className="pl-5 text-xl text-bold text-gray-500">
            <Link to="/">Home</Link>
          </Breadcrumb.Item> */}
          {title === "Instruments" ? (
            <Breadcrumb.Item className=" pl-5 text-xl font-bold text-white">
              {" "}
              Instruments
            </Breadcrumb.Item>
          ) : (
            <>
              <Breadcrumb.Item
                className="pl-5 text-xl font-bold text-gray-500"
                onClick={() => navigate(-1)}
              >
                Instruments
              </Breadcrumb.Item>
              <Breadcrumb.Item className=" text-xl font-bold text-white">
                {title}
              </Breadcrumb.Item>
            </>
          )}
        </Breadcrumb>
        {/* <div className=" flex pl-5 text-xl font-bold text-white ">{title}</div> */}
        {/* <p className="pl-5 text-small font-semibold text-gray-300">
          <div onClick={() => navigate(-1)}>Back</div>
        </p> */}

        {/* <div className="flex pl-1">
          <button
            className="hover:text-red-500 text-gray-300  font-bold  pb-5 px-4 rounded items-center"
            onClick={() => navigate(-1)}
          >
            <span> Back</span>
          </button>
        </div> */}
      </div>
    </div>
  </div>
)

export default PageHeading
