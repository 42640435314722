import * as React from "react"
import { useState } from "react"
import { Upload, message, Modal } from "antd"
import { InboxOutlined } from "@ant-design/icons"
import firebase from "gatsby-plugin-firebase"

// const FileUploader = ({ title, content, imgUrl }) => (
//   <div className="container w-50 mx-auto cursor-pointer  bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl transform hover:scale-105 duration-500">
//     <img className="w-full" src={imgUrl} alt="" />
//     <div className="text-center relative pb-8 px-3">
//       <h1 className="mb-1 text-xl font-semibold text-gray-700 hover:text-gray-900 ">
//         {title}
//       </h1>
//       <span className=" text-gray-700 hover:text-gray-900">{content}</span>
//     </div>
//   </div>
// )

const { Dragger } = Upload

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

function ProfileImgUploader({ visible, onCancel, path, multiple }) {
  const dbPath = path
  const storePath = path

  const [fileList, setFileList] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState(false)
  const [previewTitle, setPreviewTitle] = useState(false)

  const handlePreviewCancel = () => setPreviewVisible(false)

  //preview modal
  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    )
  }

  const beforeUpload = file => {
    if (!["image/jpeg", "image/png", "application/pdf"].includes(file.type)) {
      message.error(`${file.name} is not a valid file type`, 2)
      return null
    }

    const isLt2M = file.size / 1024 / 1024 < 20

    if (!isLt2M) {
      message.error("Image must smaller than 20 MB!")
      return null
    }
    return false
  }

  const handleChange = ({ fileList }) =>
    setFileList(fileList.filter(file => file.status !== "error"))

  //delete from file list
  const onRemove = async file => {
    const index = fileList.indexOf(file)
    const newFileList = fileList.slice()
    newFileList.splice(index, 1)

    setFileList(newFileList)
  }

  const handleFinish = async () => {
    try {
      setSubmitting(true)

      await Promise.all(
        fileList.map(async file => {
          const filePath = `${storePath}/${Date.now()}-${file.name}`
          const fileRef = firebase.storage().ref().child(filePath)
          try {
            const designFile = await fileRef.put(file.originFileObj)
            const downloadUrl = await designFile.ref.getDownloadURL()

            const item = {
              profileImg: downloadUrl,
            }

            await firebase.firestore().doc(dbPath).update(item)
          } catch (e) {
            console.log(e)
          }
        })
      )

      // clean up -> depart
      setFileList([])
      message.success(`Application Submitted Successfully.`, 2)
      onCancel() //close the modal after success
    } catch (err) {
      console.log(err)
      message.error(`Error Submiting Form.`, 2)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Modal
        visible={visible}
        title="Upload File"
        okText={submitting ? "Uploading" : "upload"}
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          handleFinish()
        }}
        destroyOnClose
      >
        <Dragger
          listType="picture-card"
          fileList={fileList}
          beforeUpload={beforeUpload}
          onPreview={handlePreview}
          onChange={handleChange}
          onRemove={onRemove}
          multiple={multiple}
          maxCount={10}
          //   disabled={disableUpload}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </p>
        </Dragger>
      </Modal>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handlePreviewCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  )
}

export default ProfileImgUploader
